import React from "react"
//import { graphql } from "gatsby"
import Header from "../components/header"
import SEO from "../components/seo"
//import Img from "gatsby-image"
import { Link } from "gatsby"

const about = props => {
  const nodes = [
    {
      id: "Directus__Page__1",
      title: "About",
      image: {
        localFile: {
          childImageSharp: {
            fluid: {
              aspectRatio: 1.1235955056179776,
              src: "/static/47f97d502031b092e406e03936158ed2/701ee/f55827de-c571-47f4-890a-dbfc3d245775.jpg",
              srcSet:
                "/static/47f97d502031b092e406e03936158ed2/3dcee/f55827de-c571-47f4-890a-dbfc3d245775.jpg 200w,\n/static/47f97d502031b092e406e03936158ed2/ae6ae/f55827de-c571-47f4-890a-dbfc3d245775.jpg 400w,\n/static/47f97d502031b092e406e03936158ed2/701ee/f55827de-c571-47f4-890a-dbfc3d245775.jpg 800w,\n/static/47f97d502031b092e406e03936158ed2/8c3c2/f55827de-c571-47f4-890a-dbfc3d245775.jpg 1200w,\n/static/47f97d502031b092e406e03936158ed2/c120e/f55827de-c571-47f4-890a-dbfc3d245775.jpg 1600w,\n/static/47f97d502031b092e406e03936158ed2/99289/f55827de-c571-47f4-890a-dbfc3d245775.jpg 3401w",
              sizes: "(max-width: 800px) 100vw, 800px",
              maxHeight: 710,
              maxWidth: 800,
            },
          },
        },
      },
    },
  ]
  const page = nodes.length > 0 ? nodes[0] : {}
  return (
    <>
      <SEO title="About us" />
      <Header title="About us" aboutLocation={true} />
      <div className="about">
        <div className="twoPanes">
          <div className="pane1">
            <h2 className="bb">
              <span>{page.title}</span>
            </h2>
            <div>
              <p>
                The Mobile Phone Museum has been 37 years in the making. In conjunction with our main sponsor Vodafone, we’re proud to soon be launching one of the foremost
                collections of mobile phones anywhere in the world. Over 2,000 unique devices from over 200 manufacturers.
              </p>
              <br />
              <p>Why?</p>
              <br />
              <p>
                Quite simply because mobile phones have become one of the most ubiquitous but important technologies of all time. Unprecedented in its impact and adoption, the
                mobile phone transformed personal human communication and engagement with technology. It has not only bought the world closer but has contributed massively to
                economic growth and societal progress.
              </p>
              <br />
              <p>
                The Mobile Phone Museum has been conceived to celebrate the pure brilliance of the design and impact of the age of mobile phones. To capture, preserve and share the
                stories behind some of the most iconic and impactful products of the last century. But also to educate, inform and inspire future generations of the power of design
                and technology.
              </p>
              <br />
              <p>Educating, inspiring and entertaining</p>
            </div>
            <p>
              If you have any devices you would like to donate{" "}
              <Link to="/contact" style={{ fontFamily: "Galano-Grotesque-Bold" }}>
                we’d love to hear from you
              </Link>
            </p>
          </div>
          {/*  <div className="pane2">
            {page && page.image && page.image.localFile && page.image.localFile.childImageSharp && (
              <Img
                key={"sdfsfs"}
                fluid={page.image.localFile.childImageSharp.fluid}
                style={{
                  width: "100%",
                  height: "auto",
                }}
                alt={page.title}
              />
            )}
          </div> */}
        </div>
      </div>
    </>
  )
}

export default about
